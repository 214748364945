// 应用路由
const routers = [
  {
    path: "demo",
    name: "demo",
    meta: { hidden: false },
    component: () => import("@/views/demo/List.vue"),
  },
  {
    path: "demo2",
    name: "demo2",
    meta: { hidden: false },
    component: () => import("@/views/demo2/List.vue"),
  },
  // {
  //   path: "label",
  //   name: "label",
  //   meta: { hidden: false },
  //   component: () => import("@/views/fdm/label/index.vue"),
  // },
  {
    // 树表
    path: "/fdm/demo/tree",
    name: "tree",
    meta: { hidden: false },
    component: () => import("@/views/fdm/master/index.vue"),
  },
  {
    // 单表
    path: "/fdm/demo/sample",
    name: "sample",
    meta: { hidden: false },
    component: () => import("@/views/fdm/master3/index.vue"),
  },
  {
    // 主子表
    path: "/fdm/demo/curd",
    name: "curd",
    meta: { hidden: false },
    component: () => import("@/views/sampleUser/user/index.vue"),
  },
  {
    // spu表
    path: "/fdm/demo/spu",
    name: "spu",
    meta: { hidden: false },
    component: () => import("@/views/fdm/spu/index.vue"),
  },
  {
    // store表
    path: "/fdm/demo/store",
    name: "store",
    meta: { hidden: false },
    component: () => import("@/views/fdm/store/index.vue"),
  },
  {
    // channel表
    path: "/fdm/demo/channel",
    name: "channel",
    meta: { hidden: false },
    component: () => import("@/views/fdm/channel/index.vue"),
  },
  {
    // sku表
    path: "/fdm/demo/sku",
    name: "sku",
    meta: { hidden: false },
    component: () => import("@/views/fdm/sku/index.vue"),
  },
  {
    // staff表
    path: "/fdm/demo/stff",
    name: "stff",
    meta: { hidden: false },
    component: () => import("@/views/fdm/stff/index.vue"),
  },
  {
    // acc表
    path: "/fdm/demo/acc",
    name: "acc",
    meta: { hidden: false },
    component: () => import("@/views/fdm/acc/index.vue"),
  },
  {
    // act表
    path: "/fdm/demo/act",
    name: "act",
    meta: { hidden: false },
    component: () => import("@/views/fdm/act/index.vue"),
  },
  {
    // plan表
    path: "/fdm/demo/plan",
    name: "plan",
    meta: { hidden: false },
    component: () => import("@/views/fdm/plan/index.vue"),
  },
  {
    // commodity表
    path: "/fdm/demo/commodity",
    name: "commodity",
    meta: { hidden: false },
    component: () => import("@/views/fdm/commodity/index.vue"),
  },

  {
    // sql生成
    path: "/fdm/demo/sqlRreport",
    name: "sqlRreport",
    meta: { hidden: false },
    component: () => import("@/views/fdm/sqlRreport/index.vue"),
  },
  {
    // 任务审核报表
    path: "/fdm/demo/taskAuditReport",
    name: "taskAuditReport",
    meta: { hidden: false },
    component: () => import("@/views/fdm/taskAuditReport/index.vue"),
  },
  {
    // 任务审核报表
    path: "/fdm/demo/userBusiness",
    name: "userBusiness",
    meta: { hidden: false },
    component: () => import("@/views/userBusiness/index.vue"),
  },

  {
    // 任务执行
    path: "/fdm/demo/exe",
    name: "pmdUserExe",
    meta: { hidden: false },
    component: () => import("@/views/fdm/exe/index.vue"),
  },

  {
    // 项目计划
    path: "/fdm/demo/projectPlan",
    name: "projectPlan",
    meta: { hidden: false },
    component: () => import("@/views/projectPlan/index.vue"),
  },

  {
    // 项目计划
    path: "/fdm/demo/project2Plan",
    name: "project2Plan",
    meta: { hidden: false },
    component: () => import("@/views/project2Plan/index.vue"),
  },
  {
    // vom
    path: "/fdm/demo/vom",
    name: "vom",
    meta: { hidden: false },
    component: () => import("@/views/fdm/vom/index.vue"),
  },
  {
    // vom_sql
    path: "/fdm/demo/weekVom",
    name: "weekVom",
    meta: { hidden: false },
    component: () => import("@/views/weekVom/index.vue"),
  },
  {
    // vom_api
    path: "/fdm/demo/apiWeekVom",
    name: "apiWeekVom",
    meta: { hidden: false },
    component: () => import("@/views/apiWeekVomRreport/index.vue"),
  },
];
export default routers;
