// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    disable: false,
    icon: null,
    idx: "1",
    meta: { hideIcon: true },
    name: "树表",
    openType: 1,
    path: "/fdm/demo/tree",
    path0: "/fdm/demo/tree",
    key: "tree",
  },
  {
    disable: false,
    icon: null,
    idx: "2",
    meta: { hideIcon: true },
    name: "单表",
    openType: 1,
    path: "/fdm/demo/sample",
    path0: "/fdm/demo/sample",
    key: "sample",
  },
  {
    disable: false,
    icon: null,
    idx: "3",
    meta: { hideIcon: true },
    name: "主子表",
    openType: 1,
    path: "/fdm/demo/curd",
    path0: "/fdm/demo/curd",
    key: "curd",
  },
  // {
  //   icon: null,
  //   idx: "1",
  //   meta: {},
  //   name: "demo1",
  //   key: "demo1",
  //   children: [
  //     {
  //       disable: false,
  //       icon: null,
  //       idx: "1/1",
  //       meta: { hideIcon: true },
  //       name: "demo1-1",
  //       openType: 1,
  //       path: "/demo",
  //       path0: "/demo",
  //       key: "demo1-1",
  //     },
  //     // {
  //     //   disable: false,
  //     //   icon: null,
  //     //   idx: "1/2",
  //     //   meta: { hideIcon: true },
  //     //   name: "用户标签表",
  //     //   openType: 1,
  //     //   path: "/label",
  //     //   path0: "/label",
  //     //   key: "demo1-1",
  //     // },
  //     {
  //       disable: false,
  //       icon: null,
  //       idx: "1/3",
  //       meta: { hideIcon: true },
  //       name: "树表",
  //       openType: 1,
  //       path: "/tree",
  //       path0: "/tree",
  //       key: "demo1-1",
  //     },
  //     {
  //       disable: false,
  //       icon: null,
  //       idx: "1/4",
  //       meta: { hideIcon: true },
  //       name: "单表",
  //       openType: 1,
  //       path: "/master3",
  //       path0: "/master3",
  //       key: "demo1-2",
  //     },
  //     {
  //       disable: false,
  //       icon: null,
  //       idx: "1/5",
  //       meta: { hideIcon: true },
  //       name: "主子表",
  //       openType: 1,
  //       path: "/user",
  //       path0: "/user",
  //       key: "demo1-3",
  //     },
  //   ],
  // },
];
export default menus;
